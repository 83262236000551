
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { environment } from "../../../environments/environment";

@Injectable()
export class LogInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const started = Date.now();
        return next.handle(req)
            .pipe(
                tap(event => {
                    if (!environment.production) {
                        if (event instanceof HttpResponse) {
                            const elapsed = Date.now() - started;
                            console.log(`Request ${req.method} ${req.urlWithParams} took ${elapsed} ms.`);
                        }
                    }
                }));
    }
}
