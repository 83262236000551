import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { HunterLocation } from "../../model/HunterLocation";
import { BaseService } from "./base.service";

@Injectable()
export class LocationService extends BaseService<HunterLocation> {

    constructor(protected http: HttpClient) {
        super(http, "location");
    }
}
