import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, catchError } from "rxjs";
import { HunterDevice } from "../../model/HunterDevice";
import { HunterFeature } from "../../model/HunterFeature";
import { HunterOrigin } from "../../model/HunterOrigin";
import { HunterPort } from "../../model/HunterPort";
import { HunterPurpose } from "../../model/HunterPurpose";
import { HunterSource } from "../../model/HunterSource";
import { BaseService } from "./base.service";

@Injectable()
export class SourceService extends BaseService<HunterSource> {
    constructor(protected http: HttpClient) {
        super(http, "source");
    }
}

@Injectable()
export class DeviceService extends BaseService<HunterDevice> {
    constructor(protected http: HttpClient) {
        super(http, "device");
    }
}

@Injectable()
export class PortService extends BaseService<HunterPort> {
    constructor(protected http: HttpClient) {
        super(http, "port");
    }
}

@Injectable()
export class OriginService extends BaseService<HunterOrigin> {
    constructor(protected http: HttpClient) {
        super(http, "origin");
    }

    full(id: string): Observable<HunterOrigin> {
        return this.http.get<HunterOrigin>(`${this.baseURL}/full/${id}`)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    console.log("Error", error);
                    return new Observable<HunterOrigin>();
                }));
    }

}

@Injectable()
export class FeatureService extends BaseService<HunterFeature> {
    constructor(protected http: HttpClient) {
        super(http, "feature");
    }
}

@Injectable()
export class PurposeService extends BaseService<HunterPurpose> {
    constructor(protected http: HttpClient) {
        super(http, "purpose");
    }
}