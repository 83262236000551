import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "../../../../environments/environment";
import { HunterUUIDModel } from "../../model/HunterUUIDModel";

export class BaseService<T extends HunterUUIDModel> {

    protected readonly baseURL: string;

    constructor(protected http: HttpClient, protected restName: string) {
        this.baseURL = `${environment.processserver}${this.restName}`;
    }

    listAll(): Observable<T[]> {
        return this.http.get<T[]>(`${this.baseURL}/`)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    console.log("Error", error);
                    return new Observable<T[]>();
                }));
    }

    getById(id: string): Observable<T> {
        return this.http.get<T>(`${this.baseURL}/${id}`)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    console.log("Error", error);
                    return new Observable<T>();
                }));
    }

    removeById(id: string) {
        return this.http.delete<T>(`${this.baseURL}/${id}`)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    console.log("Error", error);
                    return new Observable<T>();
                }));
    }

    listByType(type: string): Observable<T[]> {
        return this.http.get<T[]>(`${this.baseURL}/bytype/${type}`)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    console.log("Error", error);
                    return new Observable<T[]>();
                }));
    }

    listByTypeAndStatus(type: string, status: string): Observable<T[]> {
        return this.http.get<T[]>(`${this.baseURL}/bytypestatus/${type}/${status}`)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    console.log("Error", error);
                    return new Observable<T[]>();
                }));
    }

    customPost(url: string, body: any): Observable<T> {
        return this.http.post<T>(url, body).pipe(
            catchError((error: HttpErrorResponse) => {
                console.log("Error", error);
                return new Observable<T>();
            }));
    }

    save(entity: T): Observable<T> {
        if (entity.id != null && entity.id !== "") {
            return this.http.put<T>(`${this.baseURL}/${entity.id}`, entity)
                .pipe(
                    catchError((error: HttpErrorResponse) => {
                        console.log("Error", error);
                        return new Observable<T>();
                    }));
        }
        return this.http.post<T>(`${this.baseURL}`, entity)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    console.log("Error", error);
                    return new Observable<T>();
                }));
    }

    delete(entity: T): Observable<T> {
        if (entity != null && entity.id != null) {
            return this.http.delete<T>(`${this.baseURL}/${entity.id}`).pipe(
                catchError((error: HttpErrorResponse) => {
                    console.log("Error", error);
                    return new Observable<T>();
                }));
        }
        throw new Error("Entity is null or doesn't have an id");
    }
}
