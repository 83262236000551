
import { animate, state, style, transition, trigger } from "@angular/animations";
import { DatePipe } from "@angular/common";
import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MessageService } from "primeng/api";
import { throwError as observableThrowError } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { ResetPasswordForm } from "../interfaces/ResetPasswordForm";
import { AuthService } from "../services/auth.service";
import { TokenService } from "../services/token.service";

@Component({
    templateUrl: "login.component.html",
    styleUrls: ["./login.component.scss"],
    providers: [DatePipe],
    animations: [
        trigger("reportedUser", [state("passwordTime", style({ opacity: 0 })),
        state("usernameTime", style({ opacity: 1 })), transition("* => *", animate("250ms 5s"))])]
})
export class LoginComponent implements OnInit {
    // formLoginState = 'usernameTime';
    showUsernameInLabel = false;
    username = "";
    pwd = "";
    showPass = false;
    showResetPassword = false;
    loading = false;
    returnUrl: string;
    credentials: any;
    version: string = environment.version || "unversioned";
    resetEmail: string;
    resetPasswordContent: string;
    resetFormExpiration: Date;

    constructor(private route: ActivatedRoute, private router: Router, private authSvc: AuthService,
        private tokenSvc: TokenService, private msgSvc: MessageService, private dPipe: DatePipe) {
        this.returnUrl = this.route.snapshot.queryParams["returnUrl"] || "/home";
    }

    ngOnInit() {
        if (this.authSvc.checkMobile()) {
            this.router.navigate([this.returnUrl]);
        }
        document.documentElement.style.setProperty("--actualVH", `${window.innerHeight * 0.01}px`);
        // let step1: string = this.authSvc.deriveAKey('daym', 'fb9a6127f15a0ab8c219eb9150d734c7e61e30e92c006f3515a097bf03bb7c74', 1000);
        // console.log('Iterations','1000');
        // console.log('Salt','fb9a6127f15a0ab8c219eb9150d734c7e61e30e92c006f3515a097bf03bb7c74');
        // console.log('Salt bytes', 'let byteSalt = forge.util.hexToBytes(salt)');
        // console.log('password','daym');
        // console.log('Step1 ', 'forge.util.bytesToHex(forge.pkcs5.pbkdf2(password, byteSalt, iterations, 64));');
        // console.log('Step1', step1);
    }

    reportedName() {
        this.getCredential();
    }

    backToLogin() {
        this.showUsernameInLabel = false;
        this.username = "";
    }

    login() {
        let cred = "";
        if (this.credentials.type === "CredentialPassword") {
            const step1: string = this.authSvc.deriveAKey(this.pwd, this.credentials.salt, 1000);
            // console.log('Iterations','1000');
            // console.log('Salt',this.credentials.salt);
            // console.log('Salt bytes', 'let byteSalt = forge.util.hexToBytes(salt)');
            // console.log('password',this.pwd);
            // console.log('Step1 ', 'forge.util.bytesToHex(forge.pkcs5.pbkdf2(password, byteSalt, iterations, 64));');
            // console.log('Step1', step1);
            // console.log('Session',this.credentials.session);
            // console.log('Step2 ', 'forge.util.bytesToHex(forge.pkcs5.pbkdf2(step1, credentials.session, iterations, 64));');
            cred = this.authSvc.deriveAKey(step1, this.credentials.session, 1000);
            // console.log('Step2',cred);
        } else {
            cred = this.pwd;
        }
        this.authSvc.login(this.username, cred).subscribe({
            next: data => {
                // console.log('Validate',data);
                if ("token" in data) {
                    this.tokenSvc.setToken(data["token"]);
                    this.tokenSvc.setUid(data["userid"]);
                    this.tokenSvc.setSalt(this.credentials.salt);
                    this.router.navigate([this.returnUrl]);
                } else {
                    this.msgSvc.add({ severity: "error", summary: "Falha de Login", detail: "Favor verificar as credenciais informadas" });
                }
            },
            error: error => {
                this.msgSvc.add({ severity: "error", summary: "Falha de Login", detail: "Favor verificar as credenciais informadas" });
            }
        });
    }

    getCredential() {
        this.authSvc.getCredential(this.username).pipe(
            catchError((err: HttpErrorResponse) => observableThrowError(err)))
            .subscribe({
                next: data => {
                    // console.log('Pre Auth',data);
                    if (data != null) {
                        this.credentials = data;
                        this.showUsernameInLabel = true;
                    } else {
                        this.msgSvc.add({ severity: "error", summary: "Falha de Login", detail: "Credenciais não encontradas" });
                        this.showUsernameInLabel = false;
                    }
                },
                error: error => {
                    console.log(error);
                    if (error.status === 404) {
                        this.msgSvc.add({ severity: "error", summary: "Falha de Login", detail: "Servidor Inalcançável" });
                    } else {
                        this.msgSvc.add({ severity: "error", summary: "Falha de Login", detail: error.message });
                    }
                }
            });
    }

    togglePassword(): void {
        this.showPass = !this.showPass;
    }

    createResetPasswordForm() {
        this.authSvc.createPasswordReset({ email: this.resetEmail, login: this.username, salt: this.credentials.salt, hunterURL: window.location.origin }).pipe(
            catchError((err: HttpErrorResponse) => observableThrowError(err)))
            .subscribe({
                next: (form: ResetPasswordForm) => {
                    this.resetFormExpiration = new Date(form?.expires || (new Date().getTime() + 10 * 60 * 1000));
                    this.resetPasswordContent = `Enviado e-mail para ${this.resetEmail} válido até ${this.dPipe.transform(this.resetFormExpiration, "dd/MM/yyyy HH:mm:ss")}`;
                },
                error: e => console.log(e)
            });
    }

    checkFormExpired() {
        if (this.resetFormExpiration && this.resetFormExpiration < new Date()) {
            this.resetPasswordContent = null;
        }
    }
}
