import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { HunterDocumentModel } from "../../model/HunterDocument";
import { BaseService } from "./base.service";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";

@Injectable()
export class DocumentModelService extends BaseService<HunterDocumentModel> {
    constructor(protected http: HttpClient) {
        super(http, "documentmodel");
    }
    getByMetaname(metaname: string): Observable<HunterDocumentModel> {
        return this.http.get<HunterDocumentModel>(`${this.baseURL}/bymetaname/${metaname}`)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    console.log("Error", error);
                    return new Observable<HunterDocumentModel>();
                })
            );
    }
}
