import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of as observableOf } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "../../../../environments/environment";
import { AccessControlEvent } from "../../../custom-gpa/AccessControl/access-control.component";
import RestStatus from "../../utils/restStatus";

@Injectable()
export class CustomService {
    constructor(private http: HttpClient) {
        // no code
    }

    ymsChangeStatus(docId: string, status: string): Observable<RestStatus> {
        return this.http.put<RestStatus>(`${environment.customserver}dayman/updateStatus/${docId}`, status).pipe(
            catchError((error: HttpErrorResponse) => {
                const resp: RestStatus = { result: false, message: error.message };

                console.log("Error", error);
                return observableOf(resp);
            })
        );
    }

    ymsChangeName(docId: string, name: string): Observable<RestStatus> {
        return this.http.put<RestStatus>(`${environment.customserver}dayman/updateName/${docId}`, name).pipe(
            catchError((error: HttpErrorResponse) => {
                const resp: RestStatus = { result: false, message: error.message };

                console.log("Error", error);
                return observableOf(resp);
            })
        );
    }

    ymsChangeCode(docId: string, code: string): Observable<RestStatus> {
        return this.http.put<RestStatus>(`${environment.customserver}dayman/updateCode/${docId}`, code).pipe(
            catchError((error: HttpErrorResponse) => {
                const resp: RestStatus = { result: false, message: error.message };

                console.log("Error", error);
                return observableOf(resp);
            })
        );
    }

    ymsChangeDate(docId: string, date: Date): Observable<RestStatus> {
        const tzoffset = (new Date()).getTimezoneOffset() * 60000;

        date = (new Date(date.getTime() - tzoffset));
        return this.http.put<RestStatus>(`${environment.customserver}dayman/updateDate/${docId}`, date.toISOString()).pipe(
            catchError((error: HttpErrorResponse) => {
                const resp: RestStatus = { result: false, message: error.message };

                console.log("Error", error);
                return observableOf(resp);
            })
        );
    }

    ymsChangeDriver(docId: string, driverId: string): Observable<RestStatus> {
        return this.http.put<RestStatus>(`${environment.customserver}dayman/changeDriver/${docId}/${driverId}`, undefined).pipe(
            catchError((error: HttpErrorResponse) => {
                const resp: RestStatus = { result: false, message: error.message };

                console.log("Error", error);
                return observableOf(resp);
            })
        );
    }

    ymsChangeTruck(docId: string, truckId: string): Observable<RestStatus> {
        return this.http.put<RestStatus>(`${environment.customserver}dayman/changeTruck/${docId}/${truckId}`, undefined).pipe(
            catchError((error: HttpErrorResponse) => {
                const resp: RestStatus = { result: false, message: error.message };

                console.log("Error", error);
                return observableOf(resp);
            })
        );
    }

    ymsAppointmentForTruck(truckId: string): Observable<any> {
        return this.http.get<any>(`${environment.customserver}dayman/appointmentForTruck/${truckId}`).pipe(
            catchError((error: HttpErrorResponse) => {
                console.log("Error", error);
                return new Observable<any>();
            })
        );
    }

    ymsGetGalileuXML(foNumber: string, step: string): Observable<string> {
        return this.http.get(`${environment.customserver}integration/galileu/step/${foNumber}/${encodeURIComponent(step)}`, { responseType: "text" }).pipe(
            catchError((error: HttpErrorResponse) => {
                console.log("Error", error);
                return new Observable<string>();
            })
        );
    }

    wmsCreatePARNC(parent_id: string, items: any): Observable<RestStatus> {
        return this.http.post<RestStatus>(`${environment.customserver}document/createPARNC/${parent_id}`, items).pipe(
            catchError((error: HttpErrorResponse) => {
                const resp: RestStatus = { result: false, message: error.message };

                console.log("Error", error);
                return observableOf(resp);
            })
        );
    }

    wmsModifyAddress(json: any): Observable<RestStatus> {
        return this.http.post<RestStatus>(`${environment.customserver}wms/changeAddress/`, json).pipe(
            catchError((error: HttpErrorResponse) => {
                const resp: RestStatus = { result: false, message: error.message };

                console.log("Error", error);
                return observableOf(resp);
            })
        );
    }

    acsAccessControlEventList(person_id: string, date: string): Observable<AccessControlEvent[]> {
        return this.http.get<AccessControlEvent[]>(`${environment.customserver}accesscontrol/${person_id}/${date}`).pipe(
            catchError((error: HttpErrorResponse) => {
                console.log("Error", error);
                return observableOf([]);
            })
        );
    }
}
