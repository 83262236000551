import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { AccordionModule } from "primeng/accordion";
import { ConfirmationService, SharedModule } from "primeng/api";
import { BlockUIModule } from "primeng/blockui";
import { BreadcrumbModule } from "primeng/breadcrumb";
import { CalendarModule } from "primeng/calendar";
import { CheckboxModule } from "primeng/checkbox";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { ContextMenuModule } from "primeng/contextmenu";
import { DataViewModule } from "primeng/dataview";
import { DialogModule } from "primeng/dialog";
import { DividerModule } from "primeng/divider";
import { DropdownModule } from "primeng/dropdown";
import { DynamicDialogModule } from "primeng/dynamicdialog";
import { EditorModule } from "primeng/editor";
import { FileUploadModule } from "primeng/fileupload";
import { InplaceModule } from "primeng/inplace";
import { InputMaskModule } from "primeng/inputmask";
import { InputNumberModule } from "primeng/inputnumber";
import { InputSwitchModule } from "primeng/inputswitch";
import { InputTextModule } from "primeng/inputtext";
import { KeyFilterModule } from "primeng/keyfilter";
import { MenuModule } from "primeng/menu";
import { MultiSelectModule } from "primeng/multiselect";
import { OrderListModule } from "primeng/orderlist";
import { OverlayPanelModule } from "primeng/overlaypanel";
import { PanelModule } from "primeng/panel";
import { PasswordModule } from "primeng/password";
import { PickListModule } from "primeng/picklist";
import { ProgressBarModule } from "primeng/progressbar";
import { RadioButtonModule } from "primeng/radiobutton";
import { RippleModule } from "primeng/ripple";
import { ScrollPanelModule } from "primeng/scrollpanel";
import { SidebarModule } from "primeng/sidebar";
import { SkeletonModule } from "primeng/skeleton";
import { SliderModule } from "primeng/slider";
import { SplitButtonModule } from "primeng/splitbutton";
import { TableModule } from "primeng/table";
import { TabViewModule } from "primeng/tabview";
import { TieredMenuModule } from "primeng/tieredmenu";
import { TimelineModule } from "primeng/timeline";
import { ToastModule } from "primeng/toast";
import { ToolbarModule } from "primeng/toolbar";
import { TooltipModule } from "primeng/tooltip";
import { AlertComponent } from "./components/alert.component";
import { OrdemConferenciaComponent } from "./components/modelcomponents/conferencia.component";
import { OrdemMovimentacaoComponent } from "./components/modelcomponents/movimentacao.component";
import { NotaFiscalComponent } from "./components/modelcomponents/nota-fiscal.component";
import { PalletHistoryComponent } from "./components/modelcomponents/pallet-history.component";
import { PalletComponent } from "./components/modelcomponents/pallet.component";
import { OrdemSeparacaoComponent } from "./components/modelcomponents/separacao.component";
import { RawDataComponent } from "./components/rawdata.component";
import { HoverClassDirective } from "./directive/HoverClass.directive";
import { AutofocusDirective } from "./directive/autofocus.directive";
import { UppercaseDirective } from "./directive/uppercaseletters.directive";
import { KeyStartsWithPipe } from "./pipes/KeyStartsWithPipe";
import { HunterModelFieldParamPipe } from "./pipes/fieldParamPipe";
import { HunterFieldPipe } from "./pipes/fieldPipe";
import { FilterPropPipe } from "./pipes/filterProp";
import { FindPipe } from "./pipes/find.pipe";
import { KeysPipe } from "./pipes/keys.pipe";
import { HunterModelFieldPipe } from "./pipes/modelFieldPipe";
import { RemoveCommaPipe } from "./pipes/removeComma";
import { ArraySortPipe } from "./pipes/sort.pipe";
import { SumPipe } from "./pipes/sum.pipe";
import { ThingToPropertyPipe } from "./pipes/thing-to-property.pipe";
import { FileService } from "./services/File.service";
import { AlertService } from "./services/alert.service";
import { LocalStorageService } from "./services/localstorage.service";
import { CustomService } from "./services/model/Custom.service";
import { DocumentModelService } from "./services/model/DocumentModel.service";
import { AddressFieldService, DocumentFieldService, PersonFieldService, ProductFieldService, ThingFieldService } from "./services/model/Field.service";
import { PersonModelService } from "./services/model/PersonModel.service";
import { PrefixService } from "./services/model/PrefixService";
import { UnitService } from "./services/model/Unit.service";
import { AddressService } from "./services/model/address.service";
import { AddressModelService } from "./services/model/addressmodel.service";
import { DeviceService, FeatureService, OriginService, PortService, PurposeService, SourceService } from "./services/model/device.service";
import { DocumentService, DocumentThingService } from "./services/model/document.service";
import { GroupService } from "./services/model/group.service";
import { LocService } from "./services/model/loc.service";
import { LocationService } from "./services/model/location.service";
import { PermissionService } from "./services/model/permission.service";
import { PersonService } from "./services/model/person.service";
import { ProductService } from "./services/model/product.service";
import { ProductModelService } from "./services/model/productmodel.service";
import { TaskDefinitionService } from "./services/model/taskdefinition.service";
import { TaskPermissionService } from "./services/model/taskpermission.service";
import { ThingService } from "./services/model/thing.service";
import { NavigationService } from "./services/navigation.service";
import { SocketService } from "./services/socket.service";



@NgModule({
    imports: [CalendarModule, CommonModule, DropdownModule, FormsModule, InputTextModule, MultiSelectModule, ScrollPanelModule, TableModule, TooltipModule],
    declarations: [
        AutofocusDirective, HoverClassDirective, UppercaseDirective,
        ArraySortPipe, FilterPropPipe, FindPipe, HunterFieldPipe, HunterModelFieldPipe, HunterModelFieldParamPipe, KeysPipe, KeyStartsWithPipe, RemoveCommaPipe, SumPipe, ThingToPropertyPipe,
        AlertComponent, NotaFiscalComponent, OrdemSeparacaoComponent, OrdemMovimentacaoComponent, OrdemConferenciaComponent, PalletComponent, PalletHistoryComponent, RawDataComponent],
    providers: [SocketService, AlertService, NavigationService, LocalStorageService, ConfirmationService,
        AddressService, AddressFieldService, AddressModelService, CustomService, DeviceService, DocumentFieldService, DocumentService, DocumentModelService, DocumentThingService, FeatureService, FileService, GroupService, LocService, LocationService,
        OriginService, PersonService, PersonFieldService, PersonModelService, PermissionService, PrefixService, ProductService, ProductFieldService, ProductModelService, PortService, PurposeService, SourceService, TaskDefinitionService, TaskPermissionService, ThingService,
        ThingFieldService, UnitService
    ],
    exports: [
        AccordionModule,
        BlockUIModule, BreadcrumbModule,
        CalendarModule, CheckboxModule, CommonModule, ConfirmDialogModule, ContextMenuModule,
        DataViewModule, DialogModule, DividerModule, DropdownModule, DynamicDialogModule,
        EditorModule,
        FileUploadModule, FormsModule,
        InputNumberModule, InputMaskModule, InplaceModule, InputSwitchModule, InputTextModule,
        KeyFilterModule,
        MenuModule, MultiSelectModule,
        OrderListModule, OverlayPanelModule,
        PanelModule, PasswordModule, ProgressBarModule, PickListModule,
        RadioButtonModule, RippleModule,
        ScrollPanelModule, SharedModule, SidebarModule, SkeletonModule, SliderModule, SplitButtonModule,
        TableModule, TieredMenuModule, TimelineModule, ToastModule, ToolbarModule, TabViewModule, TooltipModule,

        AutofocusDirective, HoverClassDirective, UppercaseDirective,

        ArraySortPipe, FilterPropPipe, FindPipe, HunterFieldPipe, HunterModelFieldPipe, HunterModelFieldParamPipe, KeysPipe, KeyStartsWithPipe, RemoveCommaPipe, SumPipe, ThingToPropertyPipe,

        RawDataComponent, AlertComponent, NotaFiscalComponent, OrdemSeparacaoComponent, OrdemMovimentacaoComponent, OrdemConferenciaComponent, PalletComponent, PalletHistoryComponent]
})
export class InternalSharedModule { }
